.c-opinion{
  &__epigraph{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.56px;
    line-height: 20px;

    &:not(.u-color-gas-13, .u-color-gas-03, .u-color-gas-04) {
      color: #13516C;
    }

    &:not(.u-color-gas-04) {
      width: 135px;
      margin-left: auto;
      text-align: right;
    }
    &.u-color-gas-04{

      margin-bottom: 15px;

    }
    @media (--screen-sm-min) {
      margin-bottom: 30px;
      &.u-color-gas-04{
        text-align: right;
      }
    }
    @media (--screen-md-min){
      &:not(.u-color-gas-04) {
        width: inherit;
      }
      font-size: 36px;
      line-height: 40px;

    }
  }
  &__name{
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.6px;
    line-height: 18px;
    text-align: right;
    text-transform: uppercase;
    &:not(.u-color-gas-13, .u-color-gas-03){
      color: #00A5C1;
    }

    width: 135px;
    @media (--screen-md-min){
      font-size: 26px;
      line-height: 26px;
      margin-bottom: auto;
    }

  }
  &__signature{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    @media (--screen-sm-min) {
      margin-bottom: 0;
    }
  }
  &__thumb{
    height: 60px;
    width: 60px;
    border-radius: 50%;
    overflow: hidden;
    @media (--screen-sm-min) {
      height: 100px;
      width: 100px;
    }
  }
  &__title{
    &:not(.u-color-gas-13, .u-color-gas-03) {
      color: #00A5C1;
    }
    font-size: 15px;
    font-weight: 600;
    line-height: 27px;
    a{
      &:not(.u-color-gas-13, .u-color-gas-03) {
        color: #00A5C1;
        text-decoration-color:#33E1FF;
      }
      text-decoration: underline;
      text-underline-position: under;
      text-decoration-style: solid;
      text-decoration-thickness: 2px;
    }
    @media (--screen-md-min){
      font-size: 18px;
      line-height: 30px;
    }
  }
  @media (--screen-sm-min) {
    .u-grid-columns-2 {
      grid-template-columns: 50% 50%;
      grid-column-gap: 45px;
      width: 100%;
      max-width: 690px;
      margin: 0 auto;
    }
  }
  @media (--screen-xs-only) {
    max-width: 90%;
    margin: 0 auto;
  }
}