.c-section{
  padding: 5% 0;
  &__title {
    &:not(.u-color-black) {
      color: $white;
    }
    font-family: $font;
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 1;
    text-align: left;
    padding:5px 0 30px;
    font-weight: 600;
    &:after{
      content: '';
      display: inline-block;
      width: 5px;
      height: 5px;
      background-color: $red;
      border-radius: 50%;
      overflow: hidden;
      margin-left: 3px;
      @media (--screen-sm-min) {
        width: 10px;
        height: 10px;
        margin-left: 10px;
      }
    }
    @media (--screen-sm-min) {
      font-size: calc(72px + 1vw);
      text-align: left;

    }

  }
  &__subtitle{
    &:not(.u-color-black) {
      color: $white;
    }
    font-family: $font;
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 1.2;
    text-align: left;
    font-weight: 100;
    @media (--screen-sm-min) {
      font-size: 24px;
      margin-left: 25%;

    }
    @media (--screen-md-min) {
      max-width: calc(30% + 13.2vw);
      margin-left: 13.2vw;
    }
  }

}