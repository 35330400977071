.c-detail{
  position: relative;
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-row-gap: 15px;
  grid-column-gap: 15px;
  margin-bottom: 30px;
  .col-1-2{
    grid-column: 1 / span 2;
  }
  .col-2{
    grid-column: 2 / span 1;
  }
  .col-1{
    grid-column: 1 / span 1;
  }
  &__type{
    text-transform: uppercase;
    font-family: $font;
    color: $red;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1.25px;
    line-height: 12px;
    grid-row: 1 / span 1;
    grid-column-start: 1;
    text-align: center;
    @media (--screen-sm-min){
      text-align: left;
      font-size: 16px;
      letter-spacing: 1.54px;
      line-height: 20px;
      grid-row: 1 / span 1;

    }
  }
  &__share{
    position: sticky;
    top:calc(75px + 10%);
    color:#FFF;
    margin-top: 15px;
    .c-icon{
      background-color: $white;
      border-radius: 50%;
      color:$black;
      width: 24px;
      height: 24px;
      margin-bottom: 15px;
      svg{
        max-width: 45%;
        max-height: 45%;
      }
      @media (--screen-sm-min) {
        width: 45px;
        height: 45px;

      }
    }
    @media (--screen-sm-min) {
      top:calc(90px + 10%);
      margin-top: 60px;
    }


  }
  &__promo{
    background-color: #46321E;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & > :nth-child(1){
      width: 100%;
      max-width: 40%;
      box-sizing: border-box;
    }

    & > :nth-child(2){
      width: 100%;
      max-width: 60%;
      box-sizing: border-box;
    }
    .type{
      padding: 15px;
      text-transform: uppercase;
      color: #05DCDC;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 1.25px;
      line-height: 12px;
      @media (--screen-sm-min) {
        font-size: 30px;
        letter-spacing: 2.88px;
        line-height: 37px;
      }
    }
    .event{
      color: $white;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.96px;
      line-height: 12px;
      text-align: right;
      padding: 15px;
      span{
        text-transform: uppercase;
      }
      @media (--screen-sm-min) {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 24px;
      }

    }

  }
  &-head{
    margin-bottom: 30px;
    .col-2{
        grid-column: 1 / span 2;
        margin-top: auto;
        grid-row-start: 1;
    }

    @media (--screen-sm-min) {
      grid-row-gap: 0;
    }


  }
  &__body{
    padding: 15px;
    border-radius: 5px;
    @media (--screen-sm-min){
      padding: 60px;
      border-radius: 30px;
    }
  }
  &__name{
    display: none;
    @media (--screen-sm-min) {
      display: block;
      position: absolute;
      top: 0;
      right: -200px;
      writing-mode: vertical-rl;
      font-size: 200px;
      font-weight: 600;
      line-height: 200px;
      text-transform: uppercase;
      opacity: 0.5;
      color: #ccc;
      white-space: nowrap;
    }
  }
  &__related{
    border-radius: 5%;
  }
  &__related-kk{
    margin-top: 20px;
    margin-bottom: 80px;
    &__title{
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      color: #19BECD;
      text-transform: uppercase;
      padding: 10px 0;

    }
    &__wrap{

      counter-reset: item;
      .item{
        position: relative;
        width:100%;
        max-width: 80%;
        border-left: 1px solid rgba(180,150,130,0.5);
        border-right: 1px solid rgba(180,150,130,0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 0 auto;
        padding: 0 15px;
        background-color: $white;
        .c-talk{
          background-color: $white;
          border-bottom: 1px solid rgba(180,150,130,0.5);
          padding-bottom: 30px;
        }
        &:before {
          counter-increment: item;
          content: counter(item);
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: 90px;
          width: 90px;
          border-radius: 50%;
          background-color: rgba(180,150,130,0.1);
          position: absolute;
          top:15%;
          left:-45px;
          font-size: 40px;
          line-height: 20px;
          color:#19BECD;
        }
        &:nth-child(2n){
          &:before {
            color: #EB6EA5;
            left: inherit;
            top:inherit;
            bottom: 15%;
            right:-45px;

          }
        }
      }

    }
    @media (--screen-sm-min){
      position: relative;
      margin: 10% 0;
      transform: scale(1.12);
      &__title{
        font-size: 24px;
        padding: 5% 0;
        margin-bottom: calc(-20% + 48px);
        z-index: 2;
        position: relative;
      }
      &__wrap{
        z-index: 1;
        position: relative;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        overflow-y: hidden;
        flex-wrap: nowrap;
        padding: 0;
        width: 100%;
        max-width: 1050px;
        box-sizing: border-box;
        .item{
          margin: 180px 5% 180px 120px;
          max-width: calc(50% - 204px) !important;
          width: 100%;
          padding: 0 15px;
          flex: none;
          position: relative;
          &:before {
            height: 180px;
            width: 180px;
            top:-180px;
            left:-118px;
          }
          &:first-child{
            margin-left: 120px !important;
          }
          &:nth-child(2n){
            margin: 180px 120px 180px 5%;

            &:before {
              bottom:-180px;
              right:-118px;
            }
          }


        }
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-track {
          background-color: $white;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2.5px;
          background-color: #19BECD;
          width: 5px;
          height: 5px;
          display: block;
        }

      }
      &:before{
        content: '';
       background-color: #FFF;
        margin: 0 auto;
        width: calc(100% - 120px);
        height: calc(100% - 90px);
        position: absolute;
        top:20px;
        left:60px;
      }

    }
  }
  &__title{
    font-family: $font;
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 1;
    text-align: center;
    font-weight: 600;
    margin-bottom: 5px;
    &:after{
      content: '';
      display: inline-block;
      background-color: $red;
      border-radius: 50%;
      overflow: hidden;
      width: 5px;
      height: 5px;
      margin-left: 3px;
      @media (--screen-sm-min) {
        width: 10px;
        height: 10px;
        margin-left: 10px;
      }

    }
    @media (--screen-sm-min) {
      font-size: 96px;
      text-align: left;
      margin-bottom: 15px;

    }
  }
  &__star{
    color: #05DCDC;
    height: 20px;
    width: 20px;
    grid-column-start: 2;
    grid-row-start: 1;
    justify-self: right;
    margin-right: -20%;
    @media (--screen-sm-min) {
      height: 30px;
      width: 30px;
      grid-column-start: 3;
    }
    @media (--screen-md-min) {
      margin-right: 0;
    }
  }
  &__signature{
    .author{
      margin-left: -10%;
      margin-bottom: 5%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .thumb{
        width: 30px;
        margin: 0;
        display: block;
        border-radius: 50%;
        overflow: hidden;
        padding: 0;
        margin-left: -3.5px;
        margin-right: 10px;
      }
      .txt{
        .name{
          color: $black;
          font-family: $font;
          font-size: 10px;
          font-weight: 600;
          line-height: 16px;
        }
        .desc{
          color: $black;
          font-family: $font;
          font-size: 7px;
          letter-spacing: 0.5px;
          line-height: 13px;
        }

      }
      @media (--screen-md-min) {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0;
        padding-left: 0;
        margin-bottom: 0;
        .thumb{
          width: 60px;
          height: 60px;
          margin-bottom: 10px;
        }
        .txt{
          .name{
            font-size: 13px;
            line-height: 16px;
          }
          .desc{
            font-size: 10px;
            letter-spacing: 0.45px;
            line-height: 16px;
          }
        }
      }

    }

  }
  &__thumb{
    img{
      display: block;
    }
  }
  &__subtitle{
    color: #004650;
    font-family: $font;
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 20px;
    @media (--screen-sm-min) {
    font-size: 22px;
    line-height: 36px;
    }
  }
  &__claim{
    color: $black02;
    font-size: 22px;
    line-height: 36px;
  }
  &__aside{
    background-color: #46321E;
    padding: 15px 0 30px;
    margin-left: -20%;
    margin-right: -20%;
    margin-bottom: 20px;
    &__group{
      width: 100%;
      &__title{
        color: #05DCDC;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1.2px;
        line-height: 15px;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 30px;
        @media (--screen-md-min){
          border-top:2px solid #05DCDC;
          font-size: 11px;
          font-weight: 600;
          letter-spacing: 1.06px;
          line-height: 13px;
          text-align:left;
          padding: 5px 0 5px;
          margin-bottom: 30px;
          width: 120px;
          margin-right: 30px;
        }
      }
      &__content{

        @media (--screen-sm-max){
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          overflow-y: scroll;
          padding: 0 5%;
          .item{
            & + .item{
              margin-left: 10px;
              padding: 0 0 0 20px;
              border-left:1px solid rgba(180,150,130,0.5);
            }
          }
          .c-event{
            flex-direction: column;
            width: 150px;
            border-top: none;
            border-bottom: none;
            &__title, &__name{
              color:$white;
            }
          }
        }
      }
    }
    @media (--screen-sm-min){
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .c-event{
        border-left: none;
        align-items: flex-start;

        &__txt{
          align-items: flex-start;
          & > *{
            text-align: left;

          }
        }

        &__icon{
          right: 0;
        }
      }
    }
    @media (--screen-md-min){
      padding:0;
      background-color: $white;
      margin-left: 0;
      margin-right: 0;
      grid-row-start: 1;

    }
  }
  &__aside-t2 {
    background: #f7f4f2;
    padding: 15px 0 30px;
    margin-left: -20%;
    margin-right: -20%;
    @media (--screen-md-min){
      margin:150px -30px -30px -30px;
      padding-top: 30px;
    }
  }
  &__aside-t3{
    padding: 20px 0;

    @media (--screen-sm-min){
      display: flex;
      flex-direction: column;
      align-items: flex-end;

    }
    @media (--screen-md-min){
      padding:0;
      background-color: $white;
      margin-left: 0;
      margin-right: 0;
      grid-row-start: 1;

    }
  }

  &__summary{
    &-cols-2 {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 15px;
      padding-top: 15px;
      @media (--screen-md-min) {
        grid-template-columns: repeat(2, max(465px));
        grid-gap: 60px;
        padding-top: 30px;
        & > div {
          &:nth-child(1){
            .c-detail__summary-card{
              margin-left: auto;
              width: max-content;
            }
          }
        }


      }
    }
    &-blockquote{
        box-sizing: border-box;
        color:$black;
        font-size: 26px;
        font-weight: 600;
        line-height: 36px;
        width: 100%;
        max-width: 300px;
        margin: 0 0 15px 0;
        padding: 0;
        height: 300px;

    }
    &-card{
      margin: 0 0 15px 0;
      @media (--screen-md-min) {
        margin-bottom: 30px;
      }
      &__thumb{
        margin-bottom: 10px;
        img{
          margin-bottom: 15px;
        }
      }

      &__figcaption{
        width: 100%;
        max-width: 150px;

        p{
          color: $black;
          font-size: 8px;
          letter-spacing: 1px;
          line-height: 12px;
          text-transform: uppercase;
        }
      }
      &__txt{
        width: 100%;
        max-width: 150px;
        p{
          color:$black;
          font-size: 10px;
          letter-spacing: 0.45px;
          line-height: 16px;
        }
      }
      @media (--screen-md-min) {
        margin-bottom: 60px;
      }

    }

  }
  &__product{
    &-cols-2 {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 15px;
      padding-top: 15px;
      @media (--screen-md-min) {
        grid-template-columns: repeat(2, max(465px));
        grid-gap: 60px;
        padding-top: 30px;
      }
    }
    &-card{
      margin: 0 0 15px 0;
      @media (--screen-md-min) {
        margin-bottom: 30px;
      }
      &__thumb{
        margin-bottom: 10px;
        img{
          margin-bottom: 15px;
        }
      }

      &__top{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        .type{
          color: $gas-11;
          font-family: $font;
          font-size: 8px;
          font-weight: 600;
          letter-spacing: 1.06px;
          line-height: 13px;
          text-transform: uppercase;
          @media (--screen-sm-min) {
            font-size: 11px;
          }
        }
        .star{
          color: $gas-09;
          width: 15px;
          height: 15px;
          @media (--screen-sm-min) {
            width: 30px;
            height: 30px;
          }
        }
      }
      &__middle{
        margin-bottom: 30px;
        max-width: 90%;
        h1{
          color:$gas-06;
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
          text-transform: uppercase;
          margin-bottom: 10px;
        }
        h2{
          color:$gas-09;
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          text-transform: uppercase;
          margin-bottom: 11px;
        }
        p{
          color:$gas-06;
          font-size: 13px;
          line-height: 20px;
        }
      }
      &__bottom{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 30px;
        max-width: 90%;
        h3{
          color:$black;
          font-size: 13px;
          font-weight: 600;
          line-height: 16px;
          margin-bottom: 10px;
        }
        p{
          color:$black;
          font-size: 10px;
          letter-spacing: 0.45px;
          line-height: 16px;
        }
        a{
          color:$black;
          font-size: 10px;
          letter-spacing: 0.45px;
          line-height: 16px;
          text-decoration: none;
        }
      }
      &__txt{
        width: 100%;
        max-width: 150px;
        p{
          color:$black;
          font-size: 10px;
          letter-spacing: 0.45px;
          line-height: 16px;
        }
      }

    }

  }
  &__txt{
    & > p {
      color: $black;
      font-family: $font;
      font-size: 12px;
      line-height: 23px;
      @media (--screen-sm-min){
        font-size: 16px;
        line-height: 32px;
      }
      & + p, & + h2, & + h3  {
        margin-top: 20px;
      }
    }
    & > h2, & > h3{
      color: $gas-09;
      font-family: $font;
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      & + p  {
        margin-top: 20px;
      }
      @media (--screen-sm-min){
        font-size: 24px;
        line-height: 28px;
      }
    }

  }
  &__number{
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-color: rgba(180,150,130,0.1);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 80px;
    line-height: 20px;

  }

  &-speaker{
    .c-detail__head {
      .c-detail__title, .c-detail__subtitle{
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        @media (--screen-sm-min) {
          font-size: 30px;
          line-height: 40px;
        }
      }
      .c-detail__subtitle{
        color:#19BECD;
      }
    }
    /* c-box */
    .c-box{
      border-top: 15px solid rgba(180,150,130,0.1);
      margin-bottom: 20px;
      &__title{
        color:$black;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 15px;
        line-height: 1;
        padding-top: 12px;

      }
      &__subtitle{
        color: #19BECD;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        text-transform: uppercase;
        margin-bottom: 15px;
      }
      &__nav{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: scroll;
        a{
          text-decoration: none;
          color: $black;
          font-size: 10px;
          letter-spacing: 0.45px;
          line-height: 16px;
          & + a{
            margin-left: 5px;
          }
        }
        @media (--screen-sm-min) {
          flex-direction: column;
          overflow-x: inherit;
          a {
            & + a{
              margin-top: 5px;
              margin-left: 0;
            }
          }
        }
      }
      @media (--screen-sm-min) {
        width: 120px;
        margin-bottom: 60px;
      }
    }
  }
  &-magazine{
    .c-detail__head {
      .c-detail__title, .c-detail__subtitle{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        @media (--screen-sm-min) {
          font-size: 30px;
          line-height: 40px;
        }
      }
      .c-detail__subtitle{
        color:#19BECD;
      }
    }
  }
  &-sponsor{
    & > .c-card-thumb{
      position: relative;
      &:before{
        content: '';
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 30%;
        background: linear-gradient(180deg, #4F4F4F 0%, transparent 100%);
        z-index: 3;
      }
      &:after{
        content: '';
        position: absolute;
        top:0;
        left:0;
        background-color: rgba(51,51,51,0.65);
        width: 100%;
        height: 100%;
        z-index: 2;
      }
      img{
        z-index: 1;
      }
      height: 50vh;
      @media (--screen-sm-min) {
        height: 690px;
      }
    }
    .c-detail__head{
      box-sizing: border-box;
      .c-detail__share {
        & + .c-detail__type{
          align-self: end;
          & + .c-detail__title{
            align-self: start
          }
        }
      }
    }
    .c-detail__body{
      box-sizing: border-box;
      &:nth-child(1) {
        .c-detail__number {
          color: #05DCDC;
        }
      }
      &:nth-child(2) {
        .c-detail__number {
          color: #FF5F58;
        }
      }
      &:nth-child(3) {
        .c-detail__number {
          color: #FA9917
        }
      }
      &:nth-child(4) {
        .c-detail__number {
          color: #B49682
        }
      }
      &:nth-child(5) {
        .c-detail__number {
          color: #EB6EA5
        }
      }
      &:nth-child(6) {
        .c-detail__number {
          color: #821405
        }
      }
      &:nth-child(7) {
        .c-detail__number {
          color: #19BECD
        }
      }
      &:nth-child(8) {
        .c-detail__number {
          color: #821405
        }
      }
      &:nth-child(9) {
        .c-detail__number {
          color: #FAC300
        }
      }
      &:nth-child(10) {
        .c-detail__number {
          color: #8A5B2F
        }
      }
      &:nth-child(11) {
        .c-detail__number {
          color: #66AA70
        }
      }
      &:nth-child(12) {
        .c-detail__number {
          color: #8A4463
        }
      }
      .c-detail__subtitle{
        color: #3C828C;
        text-decoration-color: #33E1FF !important;
        text-decoration: underline;
        text-underline-position: under;
        text-decoration-style: solid;
        text-decoration-thickness: 1px !important;
      }
    }


    @media (--screen-sm-min) {
      .c-detail__type {
        grid-row-start: 1;
        grid-column: 2 / span 1;
      }
      .c-detail__title {
        grid-row-start: 2;
      }
      .c-detail__subtitle{
        font-size: 30px;
        font-weight: 600;
        line-height: 50px;
        text-transform: uppercase;
        color: #3C828C;
      }
      .c-detail__name{
        right:-120px;
      }

    }


  }
  &-legal {

    .c-detail__body {
      a {
        color: $black;
        text-decoration: underline;
        word-break: break-all;
        display: inline-block;
      }
      p, ul, ol {
        font-size: 16px;
        line-height: 1.5;
        strong {
          font-weight: 700;
        }

      }
      & > p, & > ul, & > .table-responsive  {
        margin-bottom: 15px;
      }
      h2, h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 1.4rem;
        margin-bottom: 15px;
        color: $black;
      }

      table {
        border-collapse: collapse;
        border-color: rgba(0,0,0, .5);
      }

      table td {
        padding: 10px;
        font-size: 14px;
        vertical-align: top;
        ul li, p {
          font-size: 14px;
        }

      }


      ul {
        list-style-type: disc;
        display: block;
        list-style-position: inside;
        margin-bottom: 15px;
      }



      &.text-center{
        text-align: center;
      }
    }
    @media screen and (max-width: 767px) {
      .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
      }
    }
  }

  /*@media (--screen-sm-min){
    margin: 0 auto;
    max-width: 1050px;
    &:not(.c-detail-sponsor){
      max-width: 1050px;
    }
  }*/
  @media (--screen-md-min){
    grid-template-columns: 45px 1fr;
    grid-column-gap: 30px ;
    grid-row-gap: 60px ;

    .col-1{
      grid-column: 1 / span 1;
    }
    .col-1-2{
      grid-column: 1 / span 2;
    }
    .col-2{
      grid-column: 2 / span 1;
    }

  }

}