.c-service {
  &__layout-02 {
    .item-1, .item-2, .item-3, .item-4, .item-5 {
      margin-top: 0;
      margin-bottom: 20px;
    }
    @media (--screen-sm-min) {
      grid-column-gap: 30px;
      padding: 0 45px;
      .item-1 {
        margin-bottom: 0;
      }
      .item-2 {
        margin-top: 120px;
        margin-bottom: 0;
      }
      .item-3 {
        margin-top: -90px;
        margin-bottom: 0;
      }
      .item-4 {
        margin-top: 30px;
        margin-bottom: 0;
      }
      .item-5 {
        margin-top: -90px;
        margin-bottom: 0;
      }
    }

  }
}