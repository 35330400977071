.o-section{
  position: relative;
  z-index: 1;
  &-signin{
    @media (--screen-xs-only) {
      .o-container {
        max-width: 90%;
        margin: 0 auto;
      }
    }
    .c-title{
      padding-top: 30px;
      font-size: 30px;
    }
    .c-form__button{
      font-size: 10px;
      height: 30px;
      width: 100px;
      span{
        display: none;
      }
      @media (--screen-sm-min) {
        height: 50px;
        width: 200px;
        font-size: 14px;
        font-weight: 700;
        border-radius: 30px;
        span{
          display: block;
          padding: 0 0 0 3px;
        }

      }
    }
    .c-form__check{
      padding: 20px 0;
      @media (--screen-sm-min) {
        padding: 40px 0;
      }
    }
    @media (--screen-sm-min) {

      .u-grid-columns-2{
        grid-template-columns: 50% 50%;
        div{
          &:nth-child(2){
            border-left: 1px solid rgba(180,150,130,0.5);
          }
        }
      }
    }
    @media (--screen-md-min) {
      .c-title{
        font-size: 60px;
        line-height: 1;
      }
    }
  }
  &-magazine{

    &__group{
      max-width: 690px;
      margin: 0 auto;
      padding: 20px;
      box-sizing: border-box;

      @media (--screen-sm-min) {
        padding: 40px 0;

      }
    }
    &__claim{
      .o-container{
        display: flex;
        align-items: flex-start;
        max-width: 90%;
        margin: 0 auto;
        .c-card-article{
          &:nth-child(1){
            grid-template-columns: 1fr;
            grid-row-gap: 15px;
            max-width: 50%;
            margin-right: auto;
            .c-card-article__thumb{
              margin-left: 0;
              @media (--screen-sm-min) {
                margin-bottom: 30px;
              }
            }
            @media (--screen-sm-min) {
              grid-template-columns: 1fr;
              grid-row-gap: 0;
              max-width: 255px;
            }
          }
          &:nth-child(2){
            margin-left: 15px;
            margin-top: 0;
            grid-template-columns: 1fr;
            grid-row-gap: 15px;
            .c-card-article__thumb{
              margin-left: 0;
              img{
                max-height: 120px;
                max-width: 120px;
              }

              @media (--screen-sm-min) {
                margin-left: auto;
                margin-bottom: 0;
                img{
                  max-height: inherit;
                  max-width: inherit;
                }

              }
            }
            @media (--screen-sm-min) {
              grid-template-columns: calc(50% - 30px) calc(50% - 60px);
              margin-left: auto;
              grid-row-gap: 0;
              .c-card-article__txt{
                padding-top: 30px;
              }
            }

          }
          &__type{
            line-height: 1;
          }
          &__title {
            font-size: 13px;
            line-height: 17px;
            @media (--screen-sm-min) {
              font-size: 22px;
              line-height: 28px;
            }
          }
        }
        @media (--screen-sm-min) {
          max-width: 1050px;
        }
      }

    }
    @media (--screen-sm-min) {
      .c-detail__name {
        right: 0;
      }
    }
  }
  &-contact{
    @media (--screen-sm-min) {
      .c-form__text {
        max-width: 90%;
      }
      .c-form__check{
        max-width: 90%;
        justify-content: flex-start;
        width: 100%;
      }

    }
  }
  &-welcome{
    width: 100%;
    height: 100vh;
    position: relative;
    @media (--screen-sm-min) {
      .c-section__subtitle{
        max-width: inherit;
      }
      .c-section__title{
        font-size: calc(72px + 1vw);
      }
    }
  }
  &-projects, &-services, &-posts{
    &__header{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    &__content{}
  }
  &-projects, &-posts{
    padding: 5% 0;
  }
  &-services{
    padding-bottom: 5%;
  }

}


@media (--screen-sm-min) {
  .o-section {
    &-max{
      max-width: 1290px;
      margin: 0 auto;
    }
  }
  .o-section-intro + .content{
    padding-top: 15%;
  }

}




