.o-container{
  /*max-width: 1290px;*/

  margin:0 auto;
  box-sizing: border-box;
  max-width: 90%;

}
.content{
  z-index: 1;
  position: relative;
  /*box-shadow: 0 0 50px 0 rgba(12,0,51,.1);*/
  /*.o-container{
    padding: 0;
  }*/
  &__name{
    display: none;
    @media (--screen-sm-min) {
      display: block;
      position: absolute;
      top: 100px;
      margin:0 auto;
      width:185px;
      transform: translateX(-92.5px);
      left:calc(50% + 185px);
      z-index: -1;
      .name{
        display: flex;
        flex-direction: row;
        justify-content: center;
        writing-mode: vertical-rl;
        width: 50%;
        padding-left:  525px;
        font-size: 200px;
        font-weight: 600;
        line-height: 200px;
        text-transform: uppercase;
        opacity: 0.1;
        color: #B49682;
        white-space: nowrap;
      }
    }
  }
  .o-container{

    @media (--screen-md-only){


    }
    @media (--screen-lg-min) {
      max-width: 87.5%;
      /*padding-right: 13.2vw;
      padding-left: 13.2vw;*/

    }
  }

}
