.c-filter{
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;


  height: 42px;
  position: sticky;
  top:75px;
  padding: 3vh 0;
  z-index: 2;
  background: linear-gradient($grey, 70%, hsla(231, 33%, 95%, 0));

  @media (--screen-sm-min) {
    top:90px;
    padding: 5vh 0;
    height: 52px;
  }
  &__name{
    color:$black;
    font-size: 16px; 
    margin-bottom: auto; 
    font-weight: 100;
    padding: 10px 10px 10px 0;
    @media (--screen-sm-min) {
      padding: 15px;
    }
  }
  &__options{ 
    display: flex; 
    flex-direction: row;
    overflow-x: auto;
    flex-wrap: nowrap;
    white-space: nowrap;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    z-index: 2;
    &::-webkit-scrollbar {
      display: none;
    }

  }
  &__item{  
    /*display: none;*/
    scroll-snap-align: start;
    &__name{
      padding: 10px;
      display: flex;
      width: max-content;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      /*border:1px solid $black;*/
      border-radius: 15px;
      @media (--screen-sm-min) {
        padding: 15px;
      }

    }
    &__number{
      padding: 10px 0;
      position: absolute;
      right: 0;
      top: 10px;
      width: 100px;
      text-align: right;
      display: none;
      @media (--screen-sm-min) {
        top: 15px;
        padding: 15px 1.25%;
      }
      font-weight: 100;}
    &.mixitup-control-active{

      color:$black;

      cursor: pointer;
      .c-filter__item__name{
        background-color: $white;
      }
      .c-filter__item__number{
        display: none;
        @media (--screen-sm-min) {
          display: block;
        }
      }

    }
  }
  


}
