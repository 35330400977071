[data-auth].sent label, [data-auth].sent button {
  display: none;
}
[data-auth] .message {
  display: none;
}
[data-auth].sent .message {
  display: block;
}

[data-barba="container"] {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.c-header__options .logued {
  display: none;
}
.c-header__options-logged .unlogued {
  display: none;
}
.c-header__options-logged .logued {
  display: block;
}
.c-header__options .box {
  display: none;
}
.c-user__navigation .admin {
  display: none;
}
.c-user__navigation.auth-admin .admin {
  display: block;
}

[data-auth="verify-password"] {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  top: 0;
  left: 0;
  display: none;
}
[data-auth="verify-password"] form {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: $white;
  padding: 30px;
  transform: translate(-50%);
}
[data-auth="verify-password"] .error {
  color: red;
}
/*.select2.projects {

 width: 300px;

}*/
.select2-container, .select2-drop, .select2-search, .select2-search input{
  width: calc(100% - 65px);
}

.select2-container-multi .select2-choices {
  border: none;
  background-image: none;
  padding: 0;
}