.c-title{

  &:not(.u-color-gas-08){
    color: $gas-03;
  }
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 1;
  font-family: $font;
  position: relative;
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  padding-top: 20px;
  &:not(.no-padding){
    padding-bottom: 20px;
  }
  text-transform: uppercase;
  text-align: center;
  @media (--screen-sm-min){
    font-size: 40px;
    padding-top: 40px;
    &:not(.no-padding){
      padding-bottom: 40px;
    }
  }
  &-subtitle{
    color: #004650;
    font-size: 22px;
    line-height: 36px;
    text-align: center;
    padding-bottom: 20px;
    @media (--screen-sm-min){
      padding-bottom: 40px;
    }
  }
  & + .c-card-claim{
    &:not(.c-card-claim-error) {
      padding-top: 0 !important;
    }
  }
  &.titleMag{
    color:$gas-08;
    font-size: 40px;
    @media (--screen-sm-min) {
      font-size: 90px;
    }
    @media (--screen-md-min) {
      font-size: 140px;
    }
  }
}
