.o-modal {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-width: 1050px;
  margin: 0 auto;
  &__head {
    position: relative;
    padding: 15px 20px;
    &__title {
      color: #3C828C;
      font-size: 16px;
      padding-right: 20px;
      font-weight: 500;
      line-height: 1;
      text-align: center;
      margin-bottom: 5px;
      @media (min-width: 768px) {
        font-size: 24px;
      }
    }
    &__subtitle {
      color: #3C828C;
      font-weight: 600;
      letter-spacing: 1.06px;
      line-height: 10px;
      text-align: center;
      text-transform: uppercase;
      font-size: 8px;
      @media (min-width: 768px) {
        font-size: 11px;
        line-height: 13px;
      }
    }

  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    @media (--screen-md-min) {
      top: 5px;
    }
  }
  &__nav {
    padding: 0 0 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }
  &__event{
    overflow: visible;
  }
  &__magazine{
    overflow: visible;
  }
  &__dark{
    background-color: rgba(51,51,51,0.65);
    box-shadow: 2px 2px 20px 0 rgba(180,150,130,0.1);
    max-width: inherit;
    height: 100vh;
    top: 90px;
    z-index: 4;
    overflow: hidden;
    position: fixed;
    .o-modal__close{
      top: 15px;
    }
    .o-modal__head{

      margin: 0 auto;
      padding: 5% 0 0;
      width: 90%;
      @media (--screen-sm-min) {
        width: 100%;
        padding: 0 0 0;
        max-width: 810px;
      }
      .o-modal__close{
        color:$white;
      }
    }
    .o-modal__content{
      width: 100%;
      max-width: 810px;
      margin: 0 auto;
      .c-form-login{
        margin: 0 auto;
        @media (--screen-sm-max) {
          width: 90%;
        }
        max-width: 750px;
      }
    }
    &.close{
      display: none;
    }

  }
}