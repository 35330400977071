/* paddings */

.u-pb-40{

  @media (--screen-sm-min) {
    padding-bottom: 40px !important;
  }
}
.u-pb-40-xs{
  @media (--screen-sm-max) {
    padding-bottom: 40px !important;
  }
}
.u-pb-20-xs{
  @media (--screen-sm-max) {
    padding-bottom: 20px !important;
  }
}
.u-pt-40{
  @media (--screen-sm-min) {
    padding-top: 40px !important;
  }
}

.u-pt-40-xs{
  @media (--screen-sm-max) {
    padding-top: 40px !important;
  }
}
.u-pt-15-xs{
   @media (--screen-sm-max) {
     padding-top: 15px !important;
   }
 }

.u-pt-20-xs{
  @media (--screen-sm-max) {
    padding-top: 20px !important;
  }
}


.u-pb-120{
  padding-bottom: 30px !important;
  @media (--screen-sm-min) {
    padding-bottom: 120px !important;
  }
}
.u-pt-120{
  padding-top: 30px !important;
  @media (--screen-sm-min) {
    padding-top: 120px !important;
  }
}

.u-pb-60{
  padding-bottom: 30px !important;
  @media (--screen-sm-min) {
    padding-bottom: 60px !important;
  }
}
.u-pt-60{
  padding-top: 30px !important;
  @media (--screen-sm-min) {
    padding-top: 60px !important;
  }
}

.u-pb-80{
  padding-bottom: 30px !important;
  @media (--screen-md-min) {
    padding-bottom: 80px !important;
  }
}
.u-pt-80{
  padding-top: 30px !important;
  @media (--screen-md-min) {
    padding-top: 80px !important;
  }
}

/* margins */
.u-mt-40{
  margin-top: 40px !important;
  @media (--screen-sm-min) {
    margin-top: 40px !important;
  }
}

.u-mb-40{
  margin-bottom: 40px !important;
  @media (--screen-sm-min) {
    margin-bottom: 40px !important;
  }
}
@media (--screen-sm-max) {
  .u-no-mb-xs{
      margin-bottom: 0 !important;
  }
  .u-no-mt-xs{
    margin-top: 0 !important;
  }
}