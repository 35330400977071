.c-form {

  &__title{
    text-align: center;
    color: $black;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
    /*margin-bottom: 20px;
    padding-top: 40px;*/

  }
  &__subtitle{
    color: #584B3F;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    max-width: 330px;
    margin: 0 auto;
  }
  &__msg{
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    display: block;
    background-color: $white;
    padding:40px 0;
    &__icon{
      color: $blue2;
      width: 52px;
      height:55px;
      margin:0 auto 20px;
      display: block;
    }
    &__title{
      margin: 20px 40px;
      display: block;
      font-family: $font;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #2B73A7;
    }
    &__text{
      max-width: 90%;
      margin: 0 auto;
      display: block;
      font-family: $font;
      font-size: 12px;
      line-height: 1.86;
      color: #373737;
      strong{
        font-weight: 700;
      }

      @media (min-width: 768px) {
        font-size: 14px;
      }
    }
    /*@media (min-width: 768px) {
      max-width: 290px;
    }*/
  }
  /*common*/
  &__text, &__select, &__check, &__switch{
    max-width: 90%;
    @media (--screen-md-min){
      max-width: 420px;
    }
    margin: 0 auto;
  }

  &__recaptcha{
    max-width: 90%;
    @media (--screen-md-min){
      margin: 15px auto 0;
    }
  }

  /* input text */
  &__text {
    display: block;
    margin-bottom: 20px;
    input[type="text"], input[type="email"], input[type="password"], input[type="tel"], input[type="date"] {
      border-radius: 0;
      background-color: $white;
      border: none;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      display: block;
      padding: 0;
      width: 100%;
      height: 40px;
      margin: 0;
      color: $black;
      font-family: $font;
      font-size: 14px;
      line-height: 24px;
      border-bottom: 1px solid #cacaca;
      &::placeholder, &::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(0,0,0, .5);
        opacity: 1; /* Firefox */
      }
      &:focus {
        outline: none;
        color: #1F1F1F;
      }

      @media (--screen-sm-min){
        font-size: 16px;
      }
    }
    span{
      font-size: 12px;
      font-weight: 600;
      line-height: 24px;
      color: $black;
      font-family: $font;
      @media (--screen-sm-min){
        font-size: 13px;
      }
    }
    input[type="password"]{
      letter-spacing: 3px;
    }
    textarea{
      border-radius: 0;
      background-color: $white;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      display: block;
      padding: 5px;
      width: calc(100% - 10px);
      margin: 0;
      color: $black;
      font-family: $font;
      font-size: 14px;
      line-height: 24px;
      resize: none;
      border: 1px solid #cacaca;
      &::placeholder, &::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #cacaca;
        opacity: 1; /* Firefox */
      }
      &:focus {
        outline: none;
        color: #1F1F1F;
      }
      &.error {
        &:focus {

        }
      }
      @media (--screen-sm-min){

        font-size: 16px;
      }
    }
    & > .cke{ margin-top: 15px;}
    &.error {

      input[type="text"], input[type="email"], input[type="password"], input[type="tel"], input[type="date"], textarea, .cke{
        border-color:$red;
      }
      .msg{
        color:$red;
        text-align: right;
        font-size: 14px;
        display: inline-block;
      }
    }

  }
  /*click to action*/
  &__cta{
      text-align: center;
      color: $black;
      font-family: $font;
      font-size: 9.5px;
      font-weight: 600;
      letter-spacing: 0.91px;
      line-height: 12px;
      display: block;
      width: max-content;
      margin: 0 auto;
      &:after{
        content:'';
        display: block;
        width: 100%;
        height: 2px;
        margin-top: 5px;
        background-color: $black;
      }

  }
  /* select */
  &__select {
    display: block;
    margin-bottom: 20px;
    cursor: pointer;
    label {
      display: block;
      margin-bottom: 8px;
    }
    select {
      border: none;
      height: 40px;
      width: 100%;
      box-shadow: none;
      border-radius:0;
      appearance: none;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      color: $black;
      margin: 0;
      background-size: 20px;
      padding: 0 34px 0 0;
      background: #FFF url(../img/icon/angle.svg) no-repeat 95% center;
      font-family: $font;
      font-size: 14px;
      line-height: 24px;
      border-bottom: 1px solid #cacaca;
      &:focus {
        /* border-color: #06f3f9;*/
        outline: none;
        color: #1F1F1F;
      }

      @media (--screen-sm-min){
        font-size: 16px;
      }


    }
    select::-ms-expand {
      display: none;
    }
    span{
      font-size: 12px;
      font-weight: 600;
      line-height: 24px;
      color: $black;
      font-family: $font;
      @media (--screen-sm-min){
        font-size: 13px;
      }
    }

  }

  /* check */
  &__check{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    line-height: 1;
    position: relative;
    padding-bottom: 20px;

    width:100%;
    input[type="checkbox"]{
      display: none;
      &:checked{
        & + .fake-check{
          &:after {
            background: $white url(../img/icon/check.svg) no-repeat center center;
            background-size: 90%;
          }
        }
      }
    }
    .fake-check{
      &:after {
        content: '';
        display: block;
        background: $white;
        border: 1px solid rgba(180,150,130,0.5);
        cursor: pointer;
        position: relative;
        z-index: 10;
        top: 0;
        left: 0;
        height: 12px;
        vertical-align: top;
        max-width: 12px;
        width: 12px;
        margin-right: 10px;
        border-radius: 50%;
      }
    }
    p{
      color:$black;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 0.5px;
      line-height: 13px;
      a {
        color: $black;
        display: inline;
        text-decoration: underline;

      }
    }
    @media (min-width: 768px) {
      max-width: inherit;
      justify-content: center;
      width: max-content;
    }

  }

  /*button*/
  &__button{
    cursor: pointer;
    color: #FFF;
    font-family: $font;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 11px;
    text-align: center;
    background-color: $black;
    border-radius: 20px;
    height: 30px;
    width: 120px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all .3s;
    margin: 0 auto;
    span{
      display: none;
    }
    @media (--screen-sm-min) {
      height: 50px;
      border-radius: 30px;
      width: 200px;
      font-size: 12px;
      span{
        display: block;
      }
    }
    &-dark{
      background-color: #B49682;
    }
    &-white{
      background-color: #FFF;
      color:#004650;
    }

  }

  /*switch*/
  &__switch{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(180,150,130,0.5);
    &__text{
      width: 100%;
      max-width: calc(100% - 36px);
      span{
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;
        color: $black;
        font-family: $font;
        @media (--screen-sm-min){
          font-size: 13px;
        }
      }
      p{
        height: 40px;
        font-family: $fo
        nt;
        font-size: 14px;
        line-height: 40px;
        @media (--screen-sm-min){
          font-size: 16px;
        }
      }
    }
    &__label{
      position: relative;
      display: inline-block;
      width: 36px;
      height: 20px;
      margin-top: 21px;
      input[type="checkbox"] {
        opacity: 0;
        width: 0;
        height: 0;
        display: none;
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        &:before {
          position: absolute;
          content: "";
          height: 16px;
          width: 16px;
          left: 2px;
          bottom: 2px;
          background-color: white;
          -webkit-transition: .4s;
          transition: .4s;
        }
        &.round {
          border-radius: 20px;
          &:before{
            border-radius: 50%
          }
        }
      }
      input:checked + .slider {
        background-color: $black;
      }
      input:focus + .slider {
        box-shadow: 0 0 1px $black;
      }
      input:checked + .slider:before {
        transform: translateX(15px);
      }
    }
  }

  /*check-button */
  &__check-button{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 0 20px;
    @media (--screen-sm-min) {
      border-left: 1px solid rgba(180, 150, 130, 0.5);
    }
    min-height: 225px;
    input[type="checkbox"]{
      display: none;
      &:checked{
        & + .icon {
          color:$black;
        }
      }
    }
    .thumb{
      justify-self: flex-start;
      margin-bottom: 15px;
    }
    .txt{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > * {
        text-align: center;
      }
      .speaker,
      .number{
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        margin-top: 0;
      }
      .number{
        margin-top:auto;
      }
      .title{
        color: #3C2814;
        font-size: 13px;
        line-height: 20px;
        margin-top: 15px;

      }
    }
    .icon{
      color:#FFF;
      transition: .3s color;
      position:absolute;
      top:0;
      right:5px;
      width: 20px;
      height: 20px;
    }
  }

  /*step*/
  &__step{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    &__bullet{
      display: block;
      border-radius: 50%;
      background-color: rgba(180,150,130,0.5);
      width: 5px;
      height: 5px;
      &.current{
        background-color: #584B3F;
      }
      & + .c-form__step__bullet{
        margin-left: 15px;
      }
    }

  }

  /* form login */
  &-login{
    box-sizing: border-box;
    width: 100%;
    max-width: 750px;
    background-color:$white;
    margin: 0 auto 100px;
    padding:10%;
    border-radius: 30px;
    .c-form__title{
      color: #3C828C;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
    }
    .c-form__button{
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  /*form signin*/
  &-signin{
    margin: 0 5%;
    .c-form__title{
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      color: #584B3F;
    }
    &__step-1{
      .u-grid{
        padding-bottom: 20px;
      }
      .send{
        width: max-content;
        margin: 0 auto 20px;
      }
      @media (--screen-sm-min) {
        .u-grid{
          grid-template-columns: 50% 50%;
          padding-bottom: 20px;
        }
        .u-bg-white{
          padding-bottom: 20px;
        }
      }
    }
    &__step-2{
      .send{
        width: max-content;
        margin: 0 auto 20px;
        .c-form__button{
          & + .c-form__button{
            margin-left: 15px;
          }
        }
      }
      .c-form__title{
        padding: 10%;
      }
      .u-bg-white{
        padding-bottom: 40px;
      }
      @media (--screen-sm-min) {
        .o-carousel {
          .tns-inner {

            &:before {
              background-color: #fff;
              content: '';
              height: 100%;
              width: 2px;
              position: absolute;
              top: 0;
              left: 90px;
              z-index: 4;

            }
          }
        }
      }
    }
    &__step-1, &__step-2{
      display: none;
      &.open{
        display: block;
      }
    }
    @media (--screen-sm-min) {
      max-width: 1050px;
      margin:0 auto;
      &__step-2{
        .c-form__title{
          padding:40px 0;
        }
      }
    }
  }
}

.u-bg-black{
  .c-form__button{
    color:$black;
    background-color: $gas-10;
  }
}