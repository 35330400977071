.o-scroll{
  max-width: 90%;
  margin: 0 auto;

  &__content{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    z-index: 1;
    padding-bottom: 30px;
    /*cursor: grab;*/
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2.5px;
      background-color: black;
      width: 5px;
      height: 5px;
      display: block;
    }
    &::-webkit-scrollbar-track {
      background-color: white;
    }
  }
  &__options{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 0;
  }
  &__nav{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-right: 15px;
    &__left, &__right{
      width: 40px;
      height: 40px;
      background-color: $red;
      color:$white;
      border-radius: 50%;
      display: block;
      cursor: pointer;
    }
  }

  &__item {
    scroll-snap-align: start;
    white-space: initial;
    min-width: 50%;
    box-shadow:
            1px 1px 0 1px #f9f9fb,
            -1px 0 28px 0 rgba(34, 33, 81, 0.01),
            54px 54px 28px -10px rgba(34, 33, 81, 0.15);


    & + .o-scroll__item{
      margin-left: 15px;
    }
  }
  &-right{

  }


  @media (--screen-sm-min){



    max-width: inherit;
    &__content {
      padding: 0 0 30px;
    }
    &__item {
      min-width: calc(25% - 45px);
      & + .o-scroll__item{
        margin-left: 60px;
      }
    }
    &__options{
      padding: 30px 0;
    }
    &__nav{
      padding-right: 30px;
    }

    &-right{

      .o-scroll__content {
        flex-direction: row-reverse;
      }
      .o-scroll__item{
        scroll-snap-align: end;
        & + .o-scroll__item {
          margin-left: 0;
          margin-right: 60px;
        }
      }
      .o-scroll__options{
        flex-direction: row-reverse;
      }
      .o-scroll__nav{
        padding-right: 0;
        padding-left: 30px;
      }

    }
  }
  @media (--screen-md-min) {
    margin-left: 13.2vw;
    &-right{
      margin-left: 0;
      margin-right: 13.2vw;
    }
  }
}
