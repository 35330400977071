.c-header{

  position: sticky;
  top: -1px;
  z-index: 5;
  margin-bottom: -25px;
  /*box-shadow: 0 0 20px 0 rgba(0, 0, 0, .22);*/
  .o-container{
    height: 75px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 95%;
    @media (--screen-sm-min) {
      height: 90px;
    }

  }
  &__brand{

    .sr-only{ display: none;}
    a{
      text-transform: uppercase;
      color: $white;
      height: 40px;
      width: 40px;
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
      border-radius: 50%;
      background: $green;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      @media (--screen-sm-min) {
        height: 60px;
        width: 60px;
        font-size: 20px;
      }
    }

  }
  &__nav{
    display: none;
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    a{
      color: $black;
    }
    & > a{
      margin: 0 15px;
      padding: 15px 0;
      border-top: 1px solid $grey;
    }
    @media (--screen-sm-min){
      display: flex;
      align-items: center;
      padding-left: 2.5%;
      opacity: 1;
      a{
        padding: 0;
        & + a{
          margin-left: 2.5%;
        }
        &.current{
          position: relative;
          &:after{
            content:'';
            width: 100%;
            height: 4px;
            background-color: $red;
            display: block;
            bottom:-25%;
            left:0;
            position: absolute;
            margin: 0 0 0 1.25%;
            clip-path: polygon(0 0, 100% 0, 0 70%, 0 100%);
          }
        }
      }
      & > a{
        margin: 0;
        border-top:none;
      }

    }
    @media (--screen-md-min){
      font-size: 20px;
      a{
        & + a{
          margin-left: 1.25%;
        }
      }
    }
  }
  &__options{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    &__lang{
        position: relative;
        abbr, abbr[title]{
          text-decoration: none;
          border: none;
        }
        .menu{
          all: unset;
          position: absolute;
          right: 15px;
          inset-block-start: calc(100% + 8px);
          inset-inline-start: 0;
          background-color: $white;
          min-width: 50px;
          list-style: none;
          margin-top: 5px;
          box-shadow: inset 0 0 0 1px $black;
          width: 100%;
          border-radius: 5px;
          overflow: hidden;
          & > li{
            white-space: nowrap;
            .btn{
              padding: 0.5em;
              border-radius: 5px;
            }

          }

        }
        .summary{
          background-color: $grey;
          padding: 5px;
          border-radius: 5px;
          outline: none;
          & > abbr{
            margin-right: 5px;
          }
        }
        .summary.btn::-webkit-details-marker {
          display: none;
        }
        .btn{
          cursor: pointer;
          color: $black;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          font-weight: 500;
          transition: color .3s, background-color .3s;

          svg{
            margin-right: 5px;
            color: $white;
            width: 14px;
            height: 14px;
            background-color: $red;
            border-radius: 50%;
            padding: 5px;
          }
          &:hover{
            background-color: $black;
            color: $white;
          }
        }
        &[open]{
          & > .btn{
            background-color: $black;
            color:$white;
          }
        }
      /*&::menu { display: none; }
      &[open]::menu { display: contents; }*/

    }
    &__user{
      position: relative;
      abbr, abbr[title]{
        text-decoration: none;
        border: none;
      }
      .menu{
        all: unset;
        position: absolute;
        right: 0;
        inset-block-start: calc(100% + 8px);
        /*inset-inline-start: 0;*/
        background-color: $white;
        min-width: 50px;
        list-style: none;
        margin-top: 5px;
        border-radius: 5px;
        box-shadow: inset 0 0 0 1px $black;
        width: 300px;


        & > li{
          white-space: nowrap;
          a{
            font-size: 14px;
            padding: .5em;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-weight: 500;
            svg{
              margin-left: .5em;

              width: 14px;
              height: 14px;
            }
          }
          p{
            font-size: 14px;
            padding: .5em;
          }

        }

      }
      .summary{
        background-color: $grey;
        padding: 5px;
        border-radius: 5px;
        outline: none;
        & > abbr{
          margin-right: 5px;
        }
      }
      .summary.btn::-webkit-details-marker {
        display: none;
      }
      .btn{
        cursor: pointer;
        color: $black;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 11px;
        font-weight: 500;
        min-width: 115px;
        transition: color .3s, background-color .3s;
        span{
          min-width: 79px;
          max-width: 150px;
          width: 100%;
          @media (--screen-sm-min) {
            max-width: inherit;
            width: inherit;
          }
        }
        svg{
          margin-right: 5px;
          color: $white;
          width: 14px;
          height: 14px;
          background-color: $red;
          border-radius: 50%;
          padding: 5px;
        }
        &:hover{
          background-color: $black;
          color: $white;
        }
      }
      &[open]{
        & > .btn{
          background-color: $black;
          color:$white;
        }
      }

    }
    @media (--screen-sm-min) {
      justify-content: flex-end;
      &__user{
        margin-left: auto;
        z-index: 3;
        .summary{
          padding: 10px;
        }
        &-logged{
          &.open{
            .box{
              width: 510px;
            }
          }
        }
      }
      &__lang{
        margin-left: 2.5%;
        z-index: 3;
        .summary{
          padding: 10px;
        }
        .menu{
          right: 0;
        }
      }
    }
    @media (--screen-lg-min) {
      &__lang{
        margin-left: 1.25%;
      }
    }
  }
  &__btn{
    width: 60px;
    height: 45px;
    cursor: pointer;
    outline: none;
    transform: scale(.5);
    z-index: 10;
    position: relative;
    padding: 0;
    margin-left: auto;
    span {
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: $black;
      /*border-radius: 9px;*/
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .5s ease-in-out;
      &:nth-child(1) {
        top: 0px;
        transform-origin: left center;
      }
      &:nth-child(2) {
        top: 21px;
        transform-origin: left center;
      }
      &:nth-child(3) {
        top: 42px;
        transform-origin: left center;
      }
      &.sr-only{ display: none}
    }
    @media (--screen-sm-min) {
      display: none;
    }
    &.close{
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          width: 0%;
          opacity: 0;
          transition: .1s;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &:before{
    content: '';
    background-color: $white;
    display: block;
    position: absolute;
    top:0;
    width: 100%;
    height: calc(100% - 2vh);
    z-index: -1;
  }
  &:after{
    content: '';
    clip-path: polygon(0 0, 100% 0, 0 100%);
    background-color: $white;
    height: 2vh;
    width: 100%;
    display: block;
  }



  @media (--screen-xs-only) {
    &__options{
      &__lang {
        margin-left: auto;
        .btn{
          margin-right: 15px;
          padding: 15px;
        }
      }
      &__user{
        margin: 0 15px;
        .btn{

          padding: 15px;
          font-size: 16px;
        }
      }
      &__user{
        /*flex-direction: row-reverse;
        justify-content: flex-start;
        width: calc(100% - 25px);*/
        /*.btn{
          &:nth-child(1){
            display: none;
          }
          &:nth-child(2){
            margin-left: auto;
          }
        }
        .btn-signin{
          margin-left: auto;
        }*//*
        &-logged{
          &.open{
            .box{
              width: calc(100% + 55px);
              margin-right: -15px;
              top: 40px;
              .c-user__head__txt__title,
              .c-user__head__txt__subtitle {
                display: none;
              }
              .c-user__head__thumb{ display: none;}
              .c-user__head__txt{
                max-width: inherit;
                .c-form__button{
                  margin: 0 0 30px auto;
                }
              }

            }
          }
        }*/
      }

    }
    &.open {
      position: fixed;
      width: 100%;

      .c-header__nav {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        position: absolute;
        top: 75px;
        left:0;
        width: 100vw;
        height:calc(100vh - 75px);
        background-color: $white;

      }
      .c-header__options{
       flex-direction: row;
        align-items: flex-start;
        order: -1;
        margin-top: 15px;
        &__user{
          width:100%;
          & > a{
            padding: 15px;
          }
          .menu{
            width: 100%;
          }
        }
      }
      .c-header__lang{
        margin-left: auto;
        margin-right: 15px;
        margin-bottom: 15px;
      }
      .c-header__btn{
        span {
          &:nth-child(1) {
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            width: 0%;
            opacity: 0;
            transition: .1s;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
          }
        }
      }

    }
  }



}