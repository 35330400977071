/*** Responsive ***/
.u-show-xs {
  display: block;
}
.u-hide-xs {
  display: none;
}
@media (--screen-sm-min) {
  .u-show-sm {
    &.u-flex{
      display: flex;
    }
    &:not(.u-flex){
      display: block;
    }
  }
  .u-hide-sm {
    display: none;
  }
}
@media (--screen-md-min) {
  .u-show-md {
    &.u-flex{
      display: flex;
    }
    &:not(.u-flex){
      display: block;
    }
  }
  .u-hide-md {
    display: none;
  }
}
@media (--screen-lg-min) {
  .u-show-lg {
    display: block;
  }
  .u-hide-lg {
    display: none;
  }
}
/* Only */
.u-show-xs-only {
  display: block;
  @media (--screen-sm-min) {
    display: none;
  }
  @media (--screen-md-min) {
    display: none;
  }
  @media (--screen-lg-min) {
    display: none;
  }
}
.u-show-sm-only {
  display: none;
  @media (--screen-sm-min) {
    display: block;
  }
  @media (--screen-md-min) {
    display: none;
  }
  @media (--screen-lg-min) {
    display: none;
  }
}
.u-show-md-only {
  display: none;
  @media (--screen-sm-min) {
    display: none;
  }
  @media (--screen-md-min) {
    display: block;
  }
  @media (--screen-lg-min) {
    display: none;
  }
}

.u-show-lg-only {
  display: none;
  @media (--screen-sm-min) {
    display: none;
  }
  @media (--screen-md-min) {
    display: none;
  }
  @media (--screen-lg-min) {
    display: block;
  }
}

.u-hide-xs-only {
  display: none;
  @media (--screen-sm-min) {
    display: block;
  }
  @media (--screen-md-min) {
    display: block;
  }
  @media (--screen-lg-min) {
    display: block;
  }
}

.u-hide-sm-only {
  display: block;
  @media (--screen-sm-min) {
    display: none;
  }
  @media (--screen-md-min) {
    display: block;
  }
  @media (--screen-lg-min) {
    display: block;
  }
}

.u-hide-md-only {
  display: block;
  @media (--screen-sm-min) {
    display: block;
  }
  @media (--screen-md-min) {
    display: none;
  }
  @media (--screen-lg-min) {
    display: block;
  }
}

.u-hide-lg-only {
  display: block;
  @media (--screen-sm-min) {
    display: block;
  }
  @media (--screen-md-min) {
    display: block;
  }
  @media (--screen-lg-min) {
    display: none;
  }
}