.c-subhead{
  &-blog{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    a {
      color: $gas-12;
      font-size: 9px;
      font-weight: 600;
      line-height: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      @media (--screen-sm-min) {
        font-size: 18px;
        line-height: 24px;
      }
      &:before {
        content: '';
        background-color:$gas-14;
        transform: rotate(15deg);
        width: 3px;
        display: block;
        height: 20px;
        margin: 0 5px;
        @media (--screen-sm-min) {
          height: 60px;
          margin: 0 15px;
        }
      }
    }
    &:after {
      content: '';
      background-color:$gas-14;
      transform: rotate(15deg);
      width: 3px;
      display: block;
      height: 20px;

      margin: 0 5px;
      @media (--screen-sm-min) {
        height: 60px;
        margin: 0 15px;
      }
    }
    @media (--screen-sm-min) {
      justify-content: flex-end;
    }
  }


}