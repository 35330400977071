.u-grid{
    @media(max-width: 767px){
      --gap: 15px;
      display: grid;
      grid-column-gap: var(--gap);
      grid-template-columns: 1fr;

      &-columns-2-xs {
        -ms-grid-columns: 1fr 15px 1fr;
        grid-template-columns: repeat(2, 1fr);
      }
      &-col{
        &-xs-start-1{
          grid-column-start: 1 !important;
        }
        &-xs-start-2{
          grid-column-start: 2 !important;
        }
        &-xs-start-3{
          grid-column-start: 3 !important;
        }
        &-xs-start-4{
          grid-column-start: 4 !important;
        }
        &-xs-span-1{
          grid-column: 1 / span 1;
        }
        &-xs-span-2{
          grid-column: 1 / span  2;
        }
        &-xs-span-3{
          grid-column: 1 / span  3;
        }
        &-xs-span-4{
          grid-column: 1 / span 4;
        }
      }
      &-row{
        &-xs-start-1{
          grid-row-start: 1;
        }
        &-xs-start-2{
          grid-row-start: 2;
        }
        &-xs-start-3{
          grid-row-start: 3;
        }
        &-xs-start-4{
          grid-row-start: 4;
        }
        &-xs-start-5{
          grid-row-start: 5;
        }
        &-xs-start-6{
          grid-row-start: 6;
        }
        &-xs-start-7{
          grid-row-start: 7;
        }
        &-xs-start-8{
          grid-row-start: 8;
        }
        &-xs-start-9{
          grid-row-start: 9;
        }
        &-xs-start-10{
          grid-row-start: 10;
        }

        &-xs-span-1{
          grid-row: 1 / span 1;
        }
        &-xs-span-2{
          grid-row: 1 / span  2;
        }
        &-xs-span-3{
          grid-row: 1 / span  3;
        }
        &-xs-span-4{
          grid-row: 1 / span 4;
        }

      }
    }
    @media (min-width: 768px) {
      display: grid;
      grid-column-gap: 0;
      &-columns-2 {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: repeat(2, 1fr);
      }
      &-columns-2-equals {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: repeat(2, 50%);
      }
      &-columns-3 {
        --ncols: 3;
        -ms-grid-columns: 1fr 15px 1fr 15px 1fr;
        grid-template-columns: repeat(3, 1fr);
      }
      &-columns-4 {
        --ncols: 4;
        -ms-grid-columns: 1fr 15px 1fr 15px 1fr 15px 1fr;
        grid-template-columns: repeat(4, 1fr);
      }
      &-columns-5 {
        --ncols: 5;
        -ms-grid-columns: 1fr 15px 1fr 15px 1fr 15px 1fr 15px 1fr;
        grid-template-columns: repeat(5, 1fr);
      }
      &-columns-6 {
        -ms-grid-columns: 1fr 15px 1fr 15px 1fr 15px 1fr 15px 1fr 15px 1fr;
        grid-template-columns: repeat(6, 1fr);
      }
      & > [class*="c-"]{
        flex: 1 1 auto;
        flex-direction: column;
      }

      &-start-1{
        grid-column-start: 1;
      }
      &-start-2{
        grid-column-start: 2;
      }
      &-start-3{
        grid-column-start: 3;
      }
      &-start-4{
        grid-column-start: 4;
      }
      &-start-5{
        grid-column-start: 5;
      }
      &-col{
        &-start-1{
          grid-column-start: 1 !important;
        }
        &-start-2{
          grid-column-start: 2 !important;
        }
        &-start-3{
          grid-column-start: 3 !important;
        }
        &-start-4{
          grid-column-start: 4 !important;
        }
        &-start-5{
          grid-column-start: 5 !important;
        }
        &-span-1{
          grid-column: 1 / span 1;
        }
        &-span-2{
          grid-column: 1 / span  2;
        }
        &-span-3{
          grid-column: 1 / span  3;
        }
        &-span-4{
          grid-column: 1 / span 4;
        }
        &-span-5{
          grid-column: 1 / span 5;
        }
      }
      &-row{
        &-start-1{
          grid-row-start: 1;
        }
        &-start-2{
          grid-row-start: 2;
        }
        &-start-3{
          grid-row-start: 3;
        }
        &-start-4{
          grid-row-start: 4;
        }
        &-start-5{
          grid-row-start: 5;
        }
        &-start-6{
          grid-row-start: 6;
        }
        &-start-7{
          grid-row-start: 7;
        }
        &-start-8{
          grid-row-start: 8;
        }
        &-start-9{
          grid-row-start: 9;
        }
        &-start-10{
          grid-row-start: 10;
        }
        &-span-1{
          grid-row: 1 / span 1;
        }
        &-span-2{
          grid-row: 1 / span  2;
        }
        &-span-3{
          grid-row: 1 / span  3;
        }
        &-span-4{
          grid-row: 1 / span 4;
        }
        &-span-5{
          grid-row: 1 / span 5;
        }
      }
      &-col-gap-15{
        grid-column-gap: 15px;
      }
      &-col-gap-30{
        grid-column-gap: 30px;
      }

      &-gap-30{
        grid-gap: 30px;
      }
      &-gap-60{
        grid-gap: 60px;
      }
      &-gap-20{
        grid-gap: 20px;
      }
    }
}



