.c-footer{
  position: relative;
  padding: 5% 0 0;
  .o-container{
    max-width: 95%;
  }
  /*margin-top: calc(-5% - 2vh);*/
  z-index: 2;
  &__top{
    padding: 5% 0;

  }
  &__bottom{
    padding: 5px 0;
    .c-footer__author{
      font-size: 14px;
      color:$grey;
      a{
        color:$grey;
      }
    }
  }
  &__address{
    align-self: center;
    .btn{
      color:$black;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      &:not(.phone,.mail){
        align-items: flex-start;
      }
      svg{
        color:$white;
        padding: 15px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background-color: $black;
        margin-right: 15px;
        display: block;
      }
      &:hover{
        /* color:$color-3*/
        span{
          opacity: .8;
        }

      }
      & + .btn{ margin-top: 15px;}
    }

  }
  &__nav{

      color:$black;
      a{
        color:$black;
        & + a{
          margin-top: 15px;
        }
      }

  }
  &:before{
    content: '';
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    background-color: $white;
    height: 2vh;
    width: 100%;
    display: block;
    margin-bottom: -2px;
  }
  @media (--screen-md-min) {
    .o-container{
      /*padding-right: 13.2vw;
      padding-left: 13.2vw;*/
    }
  }
}
