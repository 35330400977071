/* mw colors */
.u-color-white{
  color: $white;
}
.u-color-black{
  color: $black;
}
.u-color-red{
  color: $red;
}
.u-color-green{
  color: $green;
}
.u-color-grey{
  color: $grey;
}

/* mw backgrounds */
.u-bg-white{
  background-color: $white;
}
.u-bg-black{
  background-color: $black;
}
.u-bg-red{
  background-color: $red;
}
.u-bg-green{
  background-color: $green;
}
.u-bg-grey{
  background-color: $grey;
}

.u-bg-transparent-xs{
  @media(--screen-xs-only){
    background-color: transparent;
  }
}