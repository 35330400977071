.c-projects{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  z-index: 1;
  padding-bottom: 30px;

  .item{
    background-color: $white;
    border-radius: 30px;
    width: 100%;
    min-height: calc(65vh - 75px - 85px);
    padding: 5%;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    scroll-snap-align: start;
    & + .item{
      margin-left: 5%;
    }
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2.5px;
    background-color: $black;
    width: 5px;
    height: 5px;
    display: block;
  }
  &::-webkit-scrollbar-track {
    background-color: $white;
  }


  .c-project {
    width: 280px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    &__title {
      font-weight: 600;
      font-size: 22px;
      line-height: 25px;
      margin-bottom: 5px;
      a {
        color: $black;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
    &__year {
      font-size: 16px;
      font-weight: 700;
      color:$black;
      background-color: $grey;
      padding: 15px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-top: auto;
      margin-left: auto;
      transition: opacity .3s;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    &__thumb{
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 210px;
      @media (--screen-sm-min) {
        min-height: 270px;
      }
      img{
        border-radius: 15px 15px 0 0;
        overflow: hidden;
      }
    }
    &__txt{
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
    }
  }
  @media (--screen-sm-min) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    &::-webkit-scrollbar {
      display: none;
    }
    .item{
      min-height: inherit;

      & + .item{
        margin-left: 0;
      }
    }
    .c-project{
      width: 100%;
    }
  }
  @media (--screen-md-min) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 60px;
    grid-row-gap: 60px;
  }
}