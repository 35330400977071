.o-carousel{


  position: relative;
  z-index: 1;
  /*padding: 20px 0;*/
  &__wrap {
    z-index: 1;
  }

  /*.tns-outer{
    display: flex;
    flex-direction: column;
  }
  .tns-controls{
    order:3;
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }*/
  .tns-item{
    padding-right: 0 !important;
    margin-right: 15px;
  }

  .tns-inner{
    overflow: hidden !important;
  }

  /*[data-controls="prev"],
  [data-controls="next"]{
    width: 60px;
    outline: none;
  }
*/
  [data-controls="next"], [data-controls="prev"]{
    position: absolute;
    top:50%;
    width: 24px;
    height: 24px;
    /*margin-top: -12px;*/
    display: block;
    z-index: 2;
    border-radius: 0;
    border:none;
    background-color: transparent;
    color:$blue01;
    outline: none;
    /*text-indent: -999em;*/
    padding: 0;
    @media (--screen-sm-min) {
      /*width: 60px;
      height: 60px;
      margin-top: -30px;*/
    }

  }
  [data-controls="prev"]{
    left:0;

    @media (--screen-sm-min) {
      left:15px;
    }
  }
  [data-controls="next"]{
    right:0;
    svg {transform: rotate(180deg);}
    @media (--screen-sm-min) {
      right:15px;
    }
  }

  @media (--screen-md-min){
    /*margin-left: 0;*/
    margin-right: 0;
  }

  .tns-nav{

  }
  .tns-nav{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 0;

      button{
        cursor: pointer;
        border: none;
        height: 4px;
        width: 30px;
        background-color: rgba(5,220,220,0.3);
        & + button{
          margin-left: 10px;
        }
        &.tns-nav-active{

          background-color: $gas-10;

        }
      }
  }
}