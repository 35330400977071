.o-slider{
  position: relative;
  z-index: 1;
  &__wrap{
    z-index: 1;
  }
  [data-controls="next"], [data-controls="prev"]{
    position: absolute;
    top:122px;
    outline: 0;
    outline: none;
    height: 80px;
    width: 30px;
    margin-top: -40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 2;
    border:none;
    color:$gas-10;
    background-color: $white;
    padding: 0;
    @media (--screen-sm-min) {
      top:200px;
    }
    &:focus, &:link, &::selection {
      outline: 0;
      outline: none;
    }
  }
  [data-action="stop"],
  [data-action="start"]{
    display: none;
  }
  [data-controls="next"]{
    right:0;
    @media (--screen-sm-min) {
      right: 15px;
    }
  }

  [data-controls="prev"]{
    left:0;
    transform: rotate(180deg);
    @media (--screen-sm-min) {
      left:15px;
    }
  }
}