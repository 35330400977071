/* card */
.c-card {
  box-sizing: border-box;
  margin: 0;
  &-avatar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 90%;
    margin: 0 auto;
    padding: 40px 0;
    &__txt {
      width: 100%;
      max-width: calc(100% - 120px);
    }
    &__title {
      font-size: 12px;
      font-weight: 600;
      line-height: 24px;
      color: #19BECD;
      font-family: $font;
      @media (--screen-sm-min) {
        font-size: 13px;
      }
    }
    &__thumb {
      width: 100%;
      max-width: 120px;
      .filepond--root {
        height: 120px;
        margin: 0;
        padding: 0;
      }
      .filepond--panel-root {
        border-radius: 0;
      }
      .filepond--root .filepond--drop-label {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-family: $font;
        font-size: 13px;
      }
    }
    @media (--screen-sm-min) {
      max-width: 420px;
    }

  }
  &-project{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    border-bottom: 1px solid $grey;
    padding: 15px;
    &__thumb{
      justify-self: flex-start;
      max-width: 60px;
      max-height: 60px;
      width: 100%;
      height: 100%;
      color: $black;

    }
    &__txt{
      width: 100%;
      /*max-width: calc(100% - 60px);
      margin-left: 60px;*/
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    &__thumb{
      & + .c-card__txt {
        padding-left: 15px;
      }
    }
    &__name{
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      margin-top: 0;
      color: $black;
    }
    &__options{
      color: $black;
      font-size: 13px;
      line-height: 20px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      a{
        color: $black;
        font-size: 13px;
        line-height: 20px;
        text-decoration: underline;
        & + a {
          margin-left: 5px;
        }
      }

    }

    @media (--screen-sm-min) {
      margin-left: 30px;
      margin-right: 30px;
      &__thumb{
        max-width: 120px;
        max-height: 90px;
      }
      &__txt{
        margin-left: 0;
      }
      &__title{

      }

    }
  }
  &-project3{
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    &__thumb{
      background-color: #d1d7dd;
      background-image: linear-gradient(0deg,#c6ced5,#f6f7f9 70%);
      display: block;
      line-height: 0;
    }
    &__txt{
      padding: 20px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      background-color: $red;
      & :last-child {
        margin-top: auto;
      }
    }
    &__title{
      font-size: 24px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.32;
      letter-spacing: 0.1px;
      color: $white;
      text-transform: uppercase;
      a{
        color: $white;
        &:after{
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
        }
      }

    }
    &__subtitle{
      font-family: $font;
      font-size: 16px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: 0.1px;
      color: $color-6;
      display: none;
    }
    &__shedule{
      font-family: $font;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: 0.1px;
      color: #172b5b;
    }
    &__author{
      font-family: $font;
      font-size: 18px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: 0.1px;
      color: #172b5b;
    }
    &__share{
      position: absolute;
      top:0;
      right:0;
    }
  }
  &-project2{
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background-color: $black;
    box-shadow: 0 0 50px 0 rgba(12, 0, 51, 0.1);
    position: relative;
    transition: background-color .6s;
    z-index: 1;
    .thumb {
      line-height: 0;
      overflow: hidden;
      position: relative;
      z-index: 1;
      img{
        max-width: 100%;
        height: auto;
      }
      &:before{
        opacity: 0;
        content: '';
        background-color:$black;
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: opacity .6s;
      }
    }
    .title{
      z-index: 2;
      font-size: 18px;
      padding: 0 20px 40px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.24;
      letter-spacing: 0.2px;
      color: $black;
      a{
        color: $black;
        &:hover{
          color: $black;
        }
        &:after{
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
        }

      }
    }

    .signature{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding:20px 20px 10px;
      align-items: center;
      .date{
        color:$color-1;

        font-size: 14px;
        letter-spacing: .1px;
      }
      .category{
        text-transform: uppercase;

        font-size: 14px;
        color:$gas-04;

      }

    }
    .arrow{
      position: absolute;
      bottom: 10px;
      right:20px;
      color:$gas-04;
    }
    &:hover{
      cursor: pointer;
      /*background-color: $black;
      .title{
        a{ color:$white}
      }*/
      .arrow{
        animation: slidearrow 0.6s infinite;
        animation-timing-function: linear;
      }
      .thumb{

        /*&:before{
          opacity: .2;
        }*/
      }


    }
  }
  &-service{
    position: relative;
    &__thumb{
      z-index: 1;
      img{
        height: 100%;
        width: 100%;
        display: block;
        object-fit: cover;
        z-index: 1;
      }
      .title{
        font-size: 30px;
        font-weight: 600;
        line-height: 36px;
        text-align: center;
        text-transform: uppercase;
        z-index: 3;
        position: absolute;
        top:75px;
        width:100%;
        padding: 0 15%;
        box-sizing: border-box;

      }


    }
    &__txt{
      z-index: 2;
      position: absolute;
      top:0;
      width:100%;
      height: 100%;
      padding: 15px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }
    &__title{
      color:#FFF;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      box-sizing: border-box;
      margin: 20% auto 0;
      @media (--screen-sm-min) {
        font-size: 30px;
        line-height: 36px;
      }
    }
    &__icon{
      color:#FFF;
      width: 30px;
      height: 30px;
      margin-left: auto;
    }
    &__link{
      height: 40px;
      width: 120px;
      border-radius: 20px;
      border: 2px solid #FFF;
      color:#FFF;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 11px;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      box-sizing: border-box;
      margin: 20px auto;
      font-size: 10px;
      @media (--screen-sm-min) {
        font-size: 12px;
        margin: 40px auto;
      }

      &:after{
        transition: background-color .3s;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color:#00A5C1;
        z-index: -1;
      }
      &:hover{
        &:after{
          background-color: rgba(255,255,255, 0);
        }
      }
    }
    &-degree{
      border-radius: 15px;
      overflow: hidden;
      .c-card-service__link{
        &:after{
          background: linear-gradient(270deg,#004890,#00A5C1) #004890
        }
      }

    }
    &-red{
      border-radius: 15px;
      overflow: hidden;
      .c-card-service__link{
        &:after{
          background: #ff4f4f
        }
      }

    }
    &-blue{
      border-radius: 15px;
      overflow: hidden;
      .c-card-service__link{
        &:after{
          background: #004890;
        }
      }

    }
    &-cyan{
      border-radius: 15px;
      overflow: hidden;
      .c-card-service__link{
        &:after{
          background: #00A5C1
        }
      }

    }

  }
  &-article{
    display: grid;
    grid-template-columns: calc(50% - 15px) calc(50% - 45px);
    grid-column-gap: 30px;
    position: relative;
    &__type{
      text-transform: uppercase;
      color: $white;
      font-size: 8px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 5px;
      margin-bottom: 10px;
      @media (--screen-sm-min) {
        margin-bottom: 10px;
        font-size: 11px;
        letter-spacing: 1.06px;
        line-height: 13px;
      }
    }
    &__title{
      color: $white;
      font-size: 30px;
      font-weight: 600;
      line-height: 40px;
      a{
        color: $white;
        word-break: break-word;
        &:after{
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }
      }
    }
    &__subtitle{
      color: $white;
      font-size: 16px;
      line-height: 26px;
      word-break: break-word;
    }
    &__more {
      span {
        display: none;
        @media (--screen-sm-min) {
          display: block;
          text-align: center;
          color: $gas-07;
          font-family: $font;
          font-size: 11px;
          font-weight: 600;
          letter-spacing: 1.06px;
          line-height: 13px;
          width: max-content;
          margin: 0 0 0 auto;
          text-transform: uppercase;
          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            margin-top: 5px;
            background-color: $gas-03;
          }
        }
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }


    }
    &__thumb{
      margin-left: auto;
    }
    &-type-1{
      .c-card-article__txt{
        /*margin-top: auto;*/
      }
      .c-card-article__title {
        font-size: 16px;
        line-height: 20px;
      }
      .c-card-article__subtitle{
        display: none;
      }
      @media (--screen-sm-min) {
        .c-card-article__type{
          /*margin-bottom: 30px;*/
        }
        .c-card-article__title {
          /*max-width: 80%;*/
          font-size: 30px;
          line-height: 40px;
          margin-bottom: 45px;
        }
        .c-card-article__subtitle {
          display: block;
          max-width: 80%;
          font-size: 16px;
          line-height: 26px;
          margin-bottom: 20px;
        }
      }
    }
    &-type-2{
      .c-card-article__title {
        font-size: 13px;
        line-height: 17px;
      }
      .c-card-article__subtitle{
        display: none;
      }
      @media (--screen-sm-min) {
        .c-card-article__type{
          /*margin-bottom: 20px;*/
        }
        .c-card-article__title {
          /*font-size: 25px;
          line-height: 35px;*/
          font-size: 22px;
          line-height: 28px;
          margin-bottom: 30px;
        }
        .c-card-article__subtitle {
          display: block;
          font-size: 16px;
          line-height: 26px;
          margin-bottom: 20px;
        }
      }
    }
    &-type-3{
      /*.c-card-article__type{
        margin-bottom: 20px;
      }*/
      .c-card-article__title {
        font-size: 13px;
        line-height: 17px;
      }

      .c-card-article__subtitle{
        display: none;
      }
      @media (--screen-sm-min) {
        .c-card-article__subtitle{
          display: block;
        }
        .c-card-article__title {
          /*font-size: 18px;
          line-height: 22px;*/
          font-size: 22px;
          line-height: 28px;
          margin-bottom: 30px;
        }
      }

    }
    @media (--screen-sm-min) {
      grid-template-columns: calc(50% - 30px) calc(50% - 60px);

    }
    & + .c-card-article {
      margin-top: 30px;
    }
  }
  &-opinion{
    display: flex;
    align-items: center;
    width: 80%;
    position: relative;
    &__title{
      color:$white;
      font-size: 50px;
      font-weight: 700;
      padding: 0 30px;
      width: calc(100% - 8px);
      box-sizing: border-box;
      line-height: 1.1;
      position: relative;
      &:before{
        content: '';
        background-color: $red;
        border-radius: .4rem;
        width: 8px;
        height: 100%;
        display: block;
        position: absolute;
        top:0;
        left:0;
      }
    }


  }
  &-cookies{
    background-color: $white;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .22);
    position: sticky;
    bottom:0;
    left:0;
    width: 90%;
    z-index: 6;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 auto 15px;
    &__thumb{
      padding: 15px 15px 0;
      color:$red;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      svg{
        width: 100%;

        display: block;
      }
    }
    &__txt{
      padding: 15px;
    }
    &__title{
      font-weight: 600;
      font-size: 22px;
      margin-bottom: 15px;
    }
    &__desc{
      font-size: 14px;
      line-height: 1.3;
      margin-bottom: 15px;
    }
    &__link{
      font-size: 14px;
      line-height: 1.3;
      margin-bottom: 15px;
      text-decoration: underline;
      color:$red;
    }
    .c-form__button{
      max-width: 55%;
      margin: 0;
    }

    @media (--screen-md-min){
      flex-direction: column;
      left: calc(100% - 26.2vw);
      height: 445px;
      width: 225px;
      margin-top: -445px;
      /*bottom: 30px;*/
      bottom: calc(100% - 545px); /* px : alçada msg + header */
      float: right;
      margin-right: 2.5%;
      &__thumb{
        svg{
          max-width: 50%;
        }
      }
    }
  }
  &-test{
    box-sizing: border-box;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    margin: 0 0 30px;
    &__thumb{
      display: flex;
      flex-direction: column;
      border-radius: 30px 30px 0 0;
      width: 100%;
      img{
        margin: auto auto;
        display: block;
        object-fit: cover;
        line-height: 0;
        width: 100%;

      }
      @media (--screen-sm-min) {
        border-radius: 30px 0 0 30px;
        max-width: 50%;
      }
      & + .c-card-test__txt{
        @media (--screen-sm-min) {
          max-width: calc(100% - 300px);
        }

      }
    }
    &__txt{
      display: flex;
      flex-direction: column;
      padding: 30px;
      width: 100%;

      box-sizing: border-box;
      flex: 1;
    }

    &__title{
      font-weight: 600;
      font-size: 22px;
      line-height: 25px;
    }
    &__cta{
      margin-top: auto;
      margin-left: auto;
      font-weight: 100;
      font-size: 16px;
      line-height: 25px;
      color:$black;
      background-color: $grey;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      transition: color .3s, background-color .3s;
      &:hover{
        background-color: $black;
        color:$white;
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
      }

    }
    @media (--screen-sm-min) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex: 1 1 auto;
      margin:0;
    }

  }
  &-admin{
    padding: 15px;
    display: flex;
    flex-direction: row;
    border-radius:5px;
    margin-bottom: 15px;
    &__thumb{
      min-height: 110px;
      a{
        border-radius: 50%;
        background-color: #FFF;
        width: 60px;
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center
      }
    }

    &__txt{
      padding: 0 0 0 15px;
    }
    & + .c-card__admin{
      margin-bottom: 15px;
    }
    &__title{
      font-weight: bold;
      font-size: 20px
    }

    @media (--screen-sm-min) {
      flex-direction: column;
      &__thumb{
        min-height: 150px;
      }
      &__txt{
        margin-top: auto;
        padding: 0;
      }
      & + .c-card__admin{
        margin-bottom: 0;
      }
    }

  }
  &-post{
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background-color: $white;
    box-shadow: 0 0 50px 0 rgba(12, 0, 51, 0.1);
    position: relative;
    transition: background-color .6s;
    z-index: 1;
    &__thumb{
      line-height: 0;
      overflow: hidden;
      position: relative;
      z-index: 1;
      img{
        max-width: 100%;
        height: auto;
      }
      &:before{
        opacity: 0;
        content: '';
        background-color:$black;
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: opacity .6s;
      }
    }
    &__title{
      z-index: 2;
      font-size: 18px;
      padding: 0 20px 40px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.24;
      letter-spacing: 0.2px;
      color: $black;
      a{
        color:$black;
        /*&:hover{
          color: $color-1;
        }*/
        &:after{
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
        }

      }
    }
    &__signature{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding:20px 20px 10px;
      align-items: center;
      .date{
        color:$black;
        font-size: 14px;
        letter-spacing: .1px;
      }
      .category{
        text-transform: uppercase;
        font-size: 14px;
        color:$red;

      }

    }
    &__arrow{
      position: absolute;
      bottom: 10px;
      right:20px;
      color:$color-3;
    }
    &:hover{
      cursor: pointer;

      .c-card__arrow{
        animation: slidearrow 0.6s infinite;
        animation-timing-function: linear;
      }
      .c-card__thumb{

        &:before{
          opacity: .2;
        }
      }


    }
  }
}

@keyframes slidearrow {
  0% {right: 20px;}
  25%  {right: 25px;}
  50% {right: 30px;}
  75% {right: 25px;}
  100% {right: 20px;}
}

