.c-icon{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .sr-only{ display: none;}
  svg{
    width: 100%;
    height: 100%;
  }
}