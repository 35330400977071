.c-user{
  &__head{
    margin: 8vh 0 0;
    &__thumb{
      align-self: flex-start;
      width: 100%;
      max-width: 60px;

    }
    &__txt{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      max-width: calc(100% - 75px);
      padding-left: 15px;
      &__title{
        color: $black;
        font-size: 18px;
        line-height: 24px;
        text-align: right;
      }
      &__subtitle{
        color: $black;
        font-size: 10px;
        letter-spacing: 0.42px;
        line-height: 24px;
        text-align: right;
      }
    }

    @media (--screen-sm-min) {
      padding:0 5% 0;
      &__thumb{
        max-width: 90px;
      }
      &__txt {
        max-width: calc(100% - 115px);
        padding-left: 25px;
        &__title{
          text-align: left;
          font-size: 24px;
        }
        &__subtitle{
            text-align: left;
            font-size: 12px;
            letter-spacing: 0.5px;
        }
      }
    }
  }

  &__navigation{
    justify-content: space-between;
    position: sticky;
    top:65px;
    padding: 4vh 0 0;
    z-index: 2;

    &__item{
      color: $black;
      font-family: $font;
      font-size: 9px;
      font-weight: 600;
      letter-spacing: 0.96px;
      line-height: 1.2;
      text-align: center;
      text-transform: uppercase;
      display: block;
      padding: 5% 3.5%;
      opacity: .7;
      transition: all .3s;
      @media (--screen-sm-min) {
        & + .c-user__navigation__item{
          margin-left: 10px;
        }
      }
      &.current{
        opacity: 1;
        background-color: $white;
        border-radius: 5px 5px 0 0;
      }
    }
    @media (--screen-sm-min) {
      justify-content: flex-start;
      top:90px;
      z-index: 3;
      &__item{
        padding: 2.5% 5%;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1.06px;
      }
    }




  }
  &__option{
    margin: 0 auto 30px;

    &-preferencias{
      border-radius: 0 5px 5px 5px;
      @media (--screen-sm-min) {
        border-radius: 0 5px 5px 5px;
      }
      .c-form{
        padding: 10% 0;
      }
    }
    &-clave{
      border-radius: 5px;
      @media (--screen-sm-min) {
        border-radius: 5px;
      }
      .c-form{
        padding: 10% 0;
      }
    }
    /*&-pago{
      .c-form{
        padding: 40px 0;
      }
    }*/
    &-proyectos{
      border-radius: 5px 0 5px 5px;
      @media (--screen-sm-min) {
        border-radius: 5px;
      }
      .c-card{
        &:last-child{
          border-bottom: none;
        }
      }
    }
    /*admin*/
    &-favoritos{
      border-radius: 5px 0 5px 5px;
      @media (--screen-sm-min) {
        border-radius: 5px;
      }
      .c-user__navigation{
        &__item{
          color: rgba(180,150,130,0.5);
          font-size: 13px;
          font-weight: 600;
          line-height: 24px;
          text-align: center;
          text-transform: none;
          &.open{
            color:#3C828C;
          }
        }
        @media (--screen-sm-min) {
          max-width: inherit;
          justify-content: center;
          margin-left: 30px;
          margin-right: 30px;
          margin-bottom: 0;
          &__item{
            padding: 15px;
          }

        }
      }
      [data-tab-content]:not(.open){
        display: none;
      }

    }
    @media (--screen-sm-min){
      /*max-width: inherit;*/
      padding: 0;
      margin: 0;
    }
  }

  &__aside{
    @media (--screen-xs-only) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      white-space: nowrap;
      overflow-x: auto;
      scroll-behavior: smooth;
      scroll-snap-type: x mandatory;
      z-index: 1;
      padding-bottom: 30px;
      &::-webkit-scrollbar {
        display: none;
      }
      .c-card{
        scroll-snap-align: start;
        white-space: initial;
        min-width: 50%;
        box-sizing: content-box;
        margin-bottom: 0;
      }
    }
    .c-card-admin{
      border-radius: 15px;
    }
    .c-card-admin__thumb{
      a{
        background-color: $grey;
      }
    }
    @media (--screen-sm-min) {
      position: sticky;
      top: calc(165px + 4vh);
    }
  }
  &__content{
    border-radius: 15px;
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    @media (--screen-sm-min) {
      padding: 30px;
    }
    &__head{
      width: 100%;
      margin-bottom: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
    }
    &__title{
      box-sizing: border-box;
      line-height:1.5;
      font-weight: bold;
      a{
        color:$black;
      }
      & + span{
        padding-left: 5px;
      }
      & + a {
        margin-left: auto;
        color:$black;
      }
    }
    .table-responsive {
      /*max-width: calc(100% - 60px);
      margin: 0 auto;*/
      table {
        border-collapse: collapse;
        border-radius: 15px;
        width: 100%;
        border: 1px solid $grey;
      }
      table td {
        padding: 10px;
        font-size: 14px;
        vertical-align: middle;
      }
      table td ul li, table td p {
         font-size: 14px;
      }
      table tr:nth-child(2n +1){
        background-color: hsl(231deg 33% 96% / 19%);
      }
      table td {
        .btn{
          width: 20px;
          height: 20px;
          display: block;
          margin: auto auto;
        }
        .u-flex{
          min-width: 230px;
        }
      }
      a {

        text-decoration: none;
        word-break: break-all;
        &:not(.c-form__button){
          display: inline-block;
        }

      }
      .action{
        width: 60px;
        text-align: center;

      }
      .title{
        font-size: 16px;
        color:$black;
        font-weight: 700;
        @media (--screen-sm-min) {
          font-size: 22px;
        }
      }
      .enportada{
        border-radius: 3px;
        padding: 3px;
        background: $green;
        width: max-content;
        color: $grey;
        font-size: 11px;
        margin-top: 5px;
        display: block;

      }

      @media (--screen-xs-only) {
        /*max-width: calc(100% - 30px);*/
        width: 100%;
        /*margin-bottom: 15px;*/
        overflow-y: hidden;
      }
    }

    .c-form__text, .c-form__select, .c-form__check, .c-form__switch{
      max-width: 100%;
    }

  }
}