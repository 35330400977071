html,
body {
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
*/
}
body {
  font-family: $font;
  font-weight: 400;
  margin:0;
  padding: 0;
  font-size: 16px;
  color: $black;
  background-color: $grey;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-moz-selection {
  color: $white;
  background: $black;
}
::selection {
  color: $white;
  background: $black;
}
h1,h2,h3,h4,h5,h6 {
  font-weight: normal;
  font-family: $font;
  font-size: 20px;
  line-height: 1.3;
  margin:0;
}
address{
  font-style: normal;
}
p {
  margin: 0;
  font-size: 14px;
  line-height: 1.43;
  color:$black;
}

a {
  text-decoration: none;
  cursor: pointer;
  /*transition: all .3s;*/
  display: block;
  /*outline: none;*/
  outline-color: $black;

}
input,
button,
select,
textarea {
  font-family: $font;
  font-size: inherit;
  line-height: inherit;
  background-color: transparent;
  border:none;
  outline: none;
}
select {
  width: 100%;
  text-transform: none;
  box-sizing: border-box;
}
textarea {
  height: auto;
  min-height: 50px;
}
img {
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}
figure {
  margin: 0;
  line-height: 0;
}
figcaption {
  text-align: left;

}

ul, ol{ list-style: none; margin:0; padding: 0;}

code {
  font-family: Consolas, Monaco, monospace;
}

pre code {
  font-size: 10pt;
}