.u-flex{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  &-justify-end{
    justify-content: flex-end;
  }
  &-justify-start{
    justify-content: flex-start;
  }
}